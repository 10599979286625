export default {
    type: 'SimpleGraph',
    filter_keys: [
        ['Lead time filter', 'offered-lead-time']
    ],
    graph_settings: {
        grid: {left: '100rem'},
        x_axis_label_interval: 'auto',
        y_axis_label: 'Lead time',
        y_axis_name_gap: 35
    }
}
